<script>
  import Api from '@/services/Api.js'; 
  import EventUtils from '@/services/EventUtils.js';

  export default {

    data () {
      return {
        boxOffice: {},
        event: {},
        eventTime: {},
      }
    },
    
    created() {
//      this.$vuetify.theme.dark = true
      this.boxOffice.path = this.$route.params.boxOfficePath;
      this.event.path = this.$route.params.eventPath;
      this.eventTime.id = this.$route.params.eventTimeId;
      this.load();
    },

    methods: {
      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        this.event.boxOfficeId = this.boxOffice.id;
        this.event = await Api.post(this, "Event", "read", this.event);
        this.eventTime = await Api.post(this, "EventTime", "read", this.eventTime);
        EventUtils.downloadIcs(this.boxOffice, this.event, this.eventTime);
       },
    },

  }
</script>
